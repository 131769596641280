import { removeItemFromLocalStorage } from '@netfront/common-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { usePreventScroll } from '@react-aria/overlays';
import { useRouter } from 'next/router';

import { Button, Link, MenuDropdown, IRootMenuItem, MENU_ITEMS } from '../../../components';
import { useToast } from '../../../hooks/useToast';

const PrivateMobileMenu = () => {
  usePreventScroll();

  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { getDomain } = useDomain();

  const handleLogoutCompleted = () => {
    removeItemFromLocalStorage('userFlowIds');

    push('/').catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  const { handleLogout } = useLogout({
    onCompleted: handleLogoutCompleted,
  });

  const PRIVATE_MENU_MOBILE_ITEMS: IRootMenuItem[] = [
    ...MENU_ITEMS,
    { id: 4, href: '/user/profile', label: 'Profile' },
    { id: 5, href: '/invite-a-colleague', label: 'Invite a colleague' },
  ];

  return (
    <MenuDropdown className="relative flex flex-col h-screen overflow-y-auto top-0 xl:none">
      <div className="flex flex-1 flex-col pb-8">
        <nav className="py-8">
          <ul className="flex flex-col m-0">
            {PRIVATE_MENU_MOBILE_ITEMS.map((item) => {
              const buttonClasses = 'block color-black font-header hover:bg-grey-50 pointer px-6 py-6 weight-700';

              const { href, id, label } = item;

              return (
                <li key={id}>
                  <Link className={buttonClasses} href={href}>
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="items-center flex flex-col justify-center mb-24 mt-auto">
          <div className="flex mb-8">
            <div aria-label="Partner hub" className="mr-4">
              <Link href="/partner-hub" theme="default">
                Partner Hub
              </Link>
            </div>
            <div>
              <Button 
                theme="default" 
                onPress={() => {
                  handleLogout({
                    domain: getDomain(), 
                    path: '/'
                  })
                }}
              >
                Logout
              </Button>
            </div>
          </div>
          <div className="text-center">
            <span className="color-black">&copy; {new Date().getFullYear()} All rights reserved</span>
          </div>
        </div>
      </div>
    </MenuDropdown>
  );
};

export { PrivateMobileMenu };
