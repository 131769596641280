import { InputHTMLAttributes, useRef } from 'react';

import { useTextField } from '@react-aria/textfield';

import { TextFieldProps } from './TextField.interfaces';

const TextField = (props: TextFieldProps) => {
  const { className, inputElementType, label } = props;

  const ref = useRef(null);

  const { labelProps, inputProps } = useTextField(props, ref);

  return (
    <div className="flex flex-col flex-1" role="presentation">
      {label && <label {...labelProps}>{label}</label>}
      {inputElementType === 'input' && <input {...(inputProps as InputHTMLAttributes<HTMLInputElement>)} ref={ref} className={className} />}
      {inputElementType === 'textarea' && (
        <textarea {...(inputProps as InputHTMLAttributes<HTMLTextAreaElement>)} ref={ref} className={className} />
      )}
    </div>
  );
};

export { TextField };
