import React, { ChangeEvent, useEffect, useState } from 'react';

import { useUser, useAuthentication } from '@netfront/gelada-identity-library';
import { Input, PasswordInput, Preloader } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import noop from 'lodash/noop';
import { useRouter } from 'next/router';

import { IUserProfile, StringPasswordItem } from './ProfilePage.interfaces';

import { SingleFormPage } from '../../../components';
import { usePasswordValidation, useToast, useUpdateUser } from '../../../hooks';

const ProfilePage = () => {
  const { isAuthenticated } = useAuthentication();
  const { push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { getUser } = useUser();
  const user = getUser();

  const [userProfile, setUserProfile] = useState<IUserProfile>({
    userId: Number(user?.id),
    firstName: String(user?.firstName),
    lastName: String(user?.lastName),
    password: '',
  });

  const { handleUpdateUser, isLoading: isUpdateUserAsAdminLoading = false } = useUpdateUser({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Profile information updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { hasMinimumLength, hasNumber, hasSpecialCharacter, hasUpperAndLowerCase } = usePasswordValidation({
    firstPassword: String(userProfile.password),
  });

  const handleChangeProfileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const stringPasswordData: StringPasswordItem[] = [
    { label: 'Minimum 8 characters', successText: 'Password has minimum character amount', test: hasMinimumLength },
    { label: 'Number (0-9)', successText: 'Password contains a number', test: hasNumber },
    {
      label: 'Lowercase & uppercase',
      successText: 'Password contains an uppercase and lowercase character',
      test: hasUpperAndLowerCase,
    },
    { label: 'Special character (!@#$%^&*)', successText: 'Password contains a special character', test: hasSpecialCharacter },
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      push('/login').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  const handleSaveProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    handleUpdateUser({
      userId,
      firstName,
      lastName,
      password,
    });
  };

  const { userId, firstName, lastName, password } = userProfile;

  return (
    <>
      <Preloader isLoading={isUpdateUserAsAdminLoading} />
      <SingleFormPage className="py-8" title="Profile">
        <h1 className="color-primary h4 mb-2 weight-800">Profile</h1>

        {!isEmpty(user) && (
          <>
            <h2 className="color-blue-900 h2 mb-10">Your details</h2>

            <Input
              additionalClassNames="mb-4"
              id="firstname"
              labelText="First name"
              name="firstName"
              type="text"
              value={firstName}
              onChange={handleChangeProfileInput}
            />
            <Input
              additionalClassNames="mb-4"
              id="lastname"
              labelText="Last name"
              name="lastName"
              type="text"
              value={lastName}
              onChange={handleChangeProfileInput}
            />

            <Input
              additionalClassNames="mb-4"
              id="email"
              labelText="Email"
              name="email"
              type="email"
              value={user.credential.email}
              isReadOnly
              onChange={noop}
            />
            <PasswordInput id="newPassword" labelText="New password" name="password" onChange={handleChangeProfileInput} />

            <ul className="ml-0 mt-8">
              {stringPasswordData.map(({ label, successText, test }, key) => (
                <li key={key} className={`flex items-center ${test ? 'color-primary' : ''}`}>
                  {test && (
                    <>
                      <span className="sr-only">{successText}</span>
                      <img alt="Tick" aria-hidden="true" className="w-4 h-4 mr-2 transition-200" src="/images/ctls/tick-icon.svg" />
                    </>
                  )}
                  <span className="mb-0">{label}</span>
                </li>
              ))}
            </ul>

            <button
              className="cursor items-center font-header inline-flex justify-center text-center pointer px-6 rounded-full weight-700 bg-primary color-white hover:bg-yellow-500 hover:color-white h-14 min-w-40 focus:bg-yellow-500 focus:color-white my"
              type="submit"
              onClick={handleSaveProfile}
            >
              Save
            </button>
          </>
        )}
      </SingleFormPage>
    </>
  );
};

export { ProfilePage };
