import { getAddressLine } from './DirectoryDetail.helpers';
import { DirectoryDetailProps } from './DirectoryDetail.interfaces';

import { Link } from '../../Link';

export const DirectoryDetail = ({ directory: { address, description, email, phoneNumber, url } }: DirectoryDetailProps) => (
  <div className="flex flex-col md:flex-row -mx-8">
    <div className="flex flex-col px-8">
      {address ? (
        <>
          <h3 className="color-blue-800 h6 mb-0">Address</h3>
          <p className="mb-8">{getAddressLine(address)}</p>
        </>
      ) : null}

      {email ? (
        <>
          <h3 className="color-blue-800 h6 mb-0">Contact email</h3>
          <Link className="mb-8 color-accent text-underline weight-700" href={`mailto:${email}`}>
            {email}
          </Link>
        </>
      ) : null}

      {phoneNumber ? (
        <>
          <h3 className="color-blue-800 h6 mb-0">Contact number</h3>
          <Link className="mb-8 color-accent text-underline weight-700" href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </Link>
        </>
      ) : null}

      {url ? (
        <>
          <h3 className="color-blue-800 h6 mb-0">Website</h3>
          <Link className="mb-8 color-accent text-underline weight-700" href={url} target="_blank">
            {url}
          </Link>
        </>
      ) : null}

      {description ? (
        <>
          <h3 className="color-blue-800 h6 mb-0">Description</h3>
          <p>{description}</p>
        </>
      ) : null}
    </div>
  </div>
);
