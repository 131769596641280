import React from 'react';

import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadcrumbItemLink } from './BreadcrumbItemLink';

const BreadcrumbItemToolboxPageLink = () => {
  return <BreadcrumbItemLink content={<BreadcrumbItem text="Toolbox" />} href="/toolbox" />;
};

export { BreadcrumbItemToolboxPageLink };
