import { ISliderSnippet } from '@netfront/ekardo-content-library';

const CTLS_HOME_PAGE_URL = 'https://ctls.com.au/';
const CTLS_LOGO_SRC = '/images/ctls/ctls-logo.jpg';
const NETFRONT_HOME_PAGE_URL = 'https://netfront.com.au/';
const RRR_LOGO_SIMPLE_SRC = '/images/ctls/rrr-logo-simple.png';
const RRR_LOGO_SRC = '/images/ctls/rrr-logo.png';
const STATIC_PAGE_REGENERATION_TIME_IN_SECONDS = 60;
const USER_NOT_ACTIVATED_MESSAGE = 'User not activated';

const PREVIOUS = 'PREV';
const NEXT = 'NEXT';

const LANDING_PAGE_IMAGE_TYPES: Record<number, string> = Object.freeze({
  0: 'home',
  1: 'consult',
  2: 'sustain',
  3: 'collaborate',
});

const LANDING_PAGE_SLIDE_DATA: ISliderSnippet = {
  autoplay: false,
  autoplaySpeed: 20000,
  arrows: true,
  id: 8880,
  infinite: true,
  sliderItems: [
    {
      headerTitle: 'Welcome',
      subHeaderText: 'Commercial & Technical Lighting Solutions',
      id: 8881,
      text: `<p class='py-2'>CTLS consults, designs, produces, and supplies lighting solutions to meet specific customer requirements and ensure industry leading outcomes.<p><a href="/public-page/about-us" class="c-slider-learn-more-link">Learn More</a>`,
      type: 'CODE',
    },
    {
      headerTitle: '',
      subHeaderText: 'Consultative Approach',
      id: 8882,
      text: `<p class='py-2'>CTLS consults with clients to design and supply custom made lighting solutions that meet specific business needs.<p><a href="/public-page/capabilities" class="c-slider-learn-more-link">Learn More</a>`,
      type: 'CODE',
    },
    {
      headerTitle: '',
      subHeaderText: 'Sustainable Vision',
      id: 8883,
      text: `<p class='py-2'>CTLS light fittings are designed and manufactured to be renewed, reused, and recycled.<p><a href="/public-page/sustainability" class="c-slider-learn-more-link">Learn More</a>`,
      type: 'CODE',
    },
    {
      headerTitle: '',
      subHeaderText: 'Collaborative Outcomes',
      id: 8884,
      text: `<p class='py-2'>CTLS collaborates with all stakeholders - clients, industry, and government - to ensure industry leading outcomes.<p><a href="/public-page/capabilities" class="c-slider-learn-more-link">Learn More</a>`,
      type: 'CODE',
    },
  ],
  slidesToShow: 1,
  speed: 1000,
  title: 'Welcome slides',
};

const pageConstants = Object.freeze({
  CTLS_HOME_PAGE_URL: CTLS_HOME_PAGE_URL,
  CTLS_LOGO_SRC: CTLS_LOGO_SRC,
  LANDING_PAGE_IMAGE_TYPES: LANDING_PAGE_IMAGE_TYPES,
  LANDING_PAGE_SLIDE_DATA: LANDING_PAGE_SLIDE_DATA,
  NETFRONT_HOME_PAGE_URL: NETFRONT_HOME_PAGE_URL,
  NEXT: NEXT,
  PREVIOUS: PREVIOUS,
  RRR_LOGO_SIMPLE_SRC: RRR_LOGO_SIMPLE_SRC,
  RRR_LOGO_SRC: RRR_LOGO_SRC,
  STATIC_PAGE_REGENERATION_TIME_IN_SECONDS: STATIC_PAGE_REGENERATION_TIME_IN_SECONDS,
  USER_NOT_ACTIVATED_MESSAGE: USER_NOT_ACTIVATED_MESSAGE,
});

export { pageConstants };
