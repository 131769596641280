import { useEffect } from 'react';

import { useContentPageContext } from '@netfront/ekardo-content-library';
import { useAuthentication, useUser } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import isEmpty from 'lodash.isempty';
import Head from 'next/head';

import { IMeta, PageProps } from './Page.interfaces';

import { PublicFooter } from '../../Footer';
import { PrivateHeader, PublicHeader } from '../../Header';
import { useIsMounted } from 'hooks';

const Page = ({ bodyClassName, children, className, header, isPreloaderVisible = false, meta, title }: PageProps) => {
  const { seoDescription: seoDescriptionProp, seoTitle: seoTitleProp } = meta ?? ({} as IMeta);

  const user = useUser();
  const { isAuthenticated } = useAuthentication();
  const { dispatch } = useContentPageContext();
  const { isMounted } = useIsMounted();

  const seoTitle = `${seoTitleProp ? seoTitleProp : title}`;
  const seoDescription = seoDescriptionProp ? seoDescriptionProp : seoTitleProp ? seoTitleProp : title;

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    dispatch({
      type: 'unloadContentPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return isMounted ? (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta content={seoDescription} name="description" />
        <meta content={seoTitle} property="og:title" />
        <meta content={seoDescription} property="og:description" />
        <meta content="/images/ctls/ctls-logo.png" property="og:image" />
      </Head>
      <div className={cx('flex flex-col min-height-screen', bodyClassName)}>
        {isAuthenticated ? <PrivateHeader /> : <PublicHeader />}
        <Spinner isLoading={isPreloaderVisible} />
        <main className={cx('flex-1', 'relative', className)}>{children}</main>
        <PublicFooter />
      </div>
    </>
  ) : null;
};

export { Page };
