import { getBEMClassName } from '@netfront/common-library';
import { FormField, FormFieldContainer, FORM_ELEMENT_CSS_IDENTIFIERS } from '@netfront/gelada-identity-library';
import { Label } from '@netfront/ui-library';
import cx from 'classnames';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { DIRECTORY_FORM_BLOCK_CSS_IDENTIFIERS, DIRECTORY_FORM_FIELDS, DIRECTORY_FORM_INITIAL_VALUES } from './DirectoryForm.constants';
import { IDirectoryForm } from './DirectoryForm.interfaces';

const DirectoryForm = ({ buttonClassName, isSubmitting, onSubmit }: IDirectoryForm) => {
  const {
    contactEmail: contactEmailField,
    contactNumber: contactNumberField,
    postcode: postcodeField,
    serviceDescription: serviceDescriptionField,
    siteOrganisationName: siteOrganisationNameField,
    state: stateField,
    street: streetField,
    suburb: suburbField,
    websiteUrl: websiteUrlField,
  } = DIRECTORY_FORM_FIELDS;

  const { id: contactEmailId, label: contactEmailLabel } = contactEmailField;
  const { id: contactNumberId, label: contactNumberLabel } = contactNumberField;
  const { id: postcodeId, label: postcodeLabel } = postcodeField;
  const { id: serviceDescriptionId, label: serviceDescriptionLabel } = serviceDescriptionField;
  const { id: siteOrganisationNameId, label: siteOrganisationNameLabel } = siteOrganisationNameField;
  const { id: stateId, label: stateLabel } = stateField;
  const { id: streetId, label: streetLabel } = streetField;
  const { id: suburbId, label: suburbLabel } = suburbField;
  const { id: websiteUrlId, label: websiteUrlLabel } = websiteUrlField;

  const { form: formBlockCssId } = DIRECTORY_FORM_BLOCK_CSS_IDENTIFIERS;

  const {
    button: buttonElementCssId,
    container: containerElementCssId,
    label: labelElementCssId,
    submit: submitElementCssId,
  } = FORM_ELEMENT_CSS_IDENTIFIERS;

  return (
    <Formik
      initialValues={DIRECTORY_FORM_INITIAL_VALUES}
      validationSchema={yup.object().shape({
        contactEmail: yup.string().label(contactEmailLabel).email().required(),
        contactNumber: yup.string().label(contactNumberLabel).required(),
        postcode: yup.string().label(postcodeLabel).required(),
        serviceDescription: yup.string().label(serviceDescriptionLabel).required(),
        siteOrganisationName: yup.string().label(siteOrganisationNameLabel).required(),
        state: yup.string().label(stateLabel).required(),
        street: yup.string().label(streetLabel).required(),
        suburb: yup.string().label(suburbLabel).required(),
        websiteUrl: yup.string().label(websiteUrlLabel).url().required(),
      })}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, values }) => {
        const {
          contactEmail: contactEmailError,
          contactNumber: contactNumberError,
          postcode: postcodeError,
          serviceDescription: serviceDescriptionError,
          siteOrganisationName: siteOrganisationNameError,
          state: stateError,
          street: streetError,
          suburb: suburbError,
          websiteUrl: websiteUrlError,
        } = errors;

        const {
          contactEmail: isContactEmailTouched,
          contactNumber: isContactNumberTouched,
          postcode: isPostcodeTouched,
          serviceDescription: isDescriptionTouched,
          siteOrganisationName: isNameTouched,
          state: isStateTouched,
          street: isStreetTouched,
          suburb: isSuburbTouched,
          websiteUrl: isWebsiteUrlTouched,
        } = touched;

        const { contactEmail, contactNumber, postcode, serviceDescription, siteOrganisationName, state, street, suburb, websiteUrl } =
          values;

        return (
          <Form>
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: siteOrganisationNameId,
              }}
              field={{
                id: siteOrganisationNameId,
                name: 'siteOrganisationName',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${siteOrganisationNameId}-${labelElementCssId}`)}
                    forId={siteOrganisationNameId}
                    labelText={siteOrganisationNameLabel}
                  />
                ),
                value: siteOrganisationName,
              }}
              formik={{
                errors: siteOrganisationNameError,
                touched: isNameTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: streetId,
              }}
              field={{
                id: streetId,
                name: 'street',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${streetId}-${labelElementCssId}`)}
                    forId={streetId}
                    labelText={streetLabel}
                  />
                ),
                value: street,
              }}
              formik={{
                errors: streetError,
                touched: isStreetTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: suburbId,
              }}
              field={{
                id: suburbId,
                name: 'suburb',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${suburbId}-${labelElementCssId}`)}
                    forId={suburbId}
                    labelText={suburbLabel}
                  />
                ),
                value: suburb,
              }}
              formik={{
                errors: suburbError,
                touched: isSuburbTouched,
              }}
            />
            <FormFieldContainer
              css={{
                blockId: formBlockCssId,
                elementId: `state-postcode-${containerElementCssId}`,
              }}
            >
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: stateId,
                }}
                field={{
                  id: stateId,
                  name: 'state',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${stateId}-${labelElementCssId}`)}
                      forId={stateId}
                      labelText={stateLabel}
                    />
                  ),
                  value: state,
                }}
                formik={{
                  errors: stateError,
                  touched: isStateTouched,
                }}
              />
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: postcodeId,
                }}
                field={{
                  id: postcodeId,
                  name: 'postcode',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${postcodeId}-${labelElementCssId}`)}
                      forId={postcodeId}
                      labelText={postcodeLabel}
                    />
                  ),
                  value: postcode,
                }}
                formik={{
                  errors: postcodeError,
                  touched: isPostcodeTouched,
                }}
              />
            </FormFieldContainer>
            <FormFieldContainer
              css={{
                blockId: formBlockCssId,
                elementId: `contact-${containerElementCssId}`,
              }}
            >
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: contactEmailId,
                }}
                field={{
                  id: contactEmailId,
                  name: 'contactEmail',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${contactEmailId}-${labelElementCssId}`)}
                      forId={contactEmailId}
                      labelText={contactEmailLabel}
                    />
                  ),
                  type: 'email',
                  value: contactEmail,
                }}
                formik={{
                  errors: contactEmailError,
                  touched: isContactEmailTouched,
                }}
              />
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: contactNumberId,
                }}
                field={{
                  id: contactNumberId,
                  name: 'contactNumber',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${contactNumberId}-${labelElementCssId}`)}
                      forId={contactNumberId}
                      labelText={contactNumberLabel}
                    />
                  ),
                  type: 'tel',
                  value: contactNumber,
                }}
                formik={{
                  errors: contactNumberError,
                  touched: isContactNumberTouched,
                }}
              />
            </FormFieldContainer>
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: websiteUrlId,
              }}
              field={{
                id: websiteUrlId,
                name: 'websiteUrl',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${websiteUrlId}-${labelElementCssId}`)}
                    forId={websiteUrlId}
                    labelText={websiteUrlLabel}
                  />
                ),
                value: websiteUrl,
              }}
              formik={{
                errors: websiteUrlError,
                touched: isWebsiteUrlTouched,
              }}
            />
            <FormField
              css={{
                blockId: formBlockCssId,
                elementId: serviceDescriptionId,
              }}
              field={{
                id: serviceDescriptionId,
                name: 'serviceDescription',
                labelComponent: (
                  <Label
                    additionalClassNames={getBEMClassName(formBlockCssId, `${serviceDescriptionId}-${labelElementCssId}`)}
                    forId={serviceDescriptionId}
                    labelText={serviceDescriptionLabel}
                  />
                ),
                value: serviceDescription,
              }}
              formik={{
                errors: serviceDescriptionError,
                touched: isDescriptionTouched,
              }}
            />
            <FormFieldContainer
              css={{
                blockId: formBlockCssId,
                elementId: `${submitElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
              }}
            >
              <button
                className={cx(getBEMClassName(formBlockCssId, `${submitElementCssId}-${buttonElementCssId}`), buttonClassName)}
                disabled={isSubmitting}
                type="submit"
              >
                Submit
              </button>
            </FormFieldContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export { DirectoryForm };
