import { useAuthentication } from '@netfront/gelada-identity-library';

import { Link } from '../../Link';

const PreHeader = () => {
  const { isAuthenticated } = useAuthentication();

  return (
    <div className="bg-accent none xl:block">
      <div className="container-xl">
        <div className="items-center flex justify-end -mx-4">
          {isAuthenticated ? (
            <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/partner-hub">
              Go to program dashboard
            </Link>
          ) : (
            <>
              <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/register">
                Register
              </Link>
              <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/login">
                Login
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { PreHeader };
