import { Skeleton } from '@netfront/ui-library';

import { HelpButton } from '../HelpButton';
import { UserInitials } from '../UserInitials';

import { WelcomeProps } from './Welcome.interfaces';

const Welcome = ({ greeting = 'Hello', fullName, isLoading }: WelcomeProps) => (
  <div className="flex mb-12">
    <div className="flex-1 mr-4">
      <div className="flex w-full items-center flex-1">
        {isLoading ? (
          <div className="flex items-center">
            <Skeleton baseColor="#E5F7F9" height="5rem" highlightColor="#fff" width="5rem" circle />
            <Skeleton baseColor="#E5F7F9" height="5rem" highlightColor="#fff" width="100%" />
          </div>
        ) : (
          <>
            <UserInitials size="large" />
            <div className="ml-6">
              <h2 className="color-primary h2 mb-0">
                <span className="h2 ">{greeting}, </span>
                {fullName}
              </h2>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="flex items-center">
      <HelpButton />
    </div>
  </div>
);

export { Welcome };
