import { ChangeEvent, useEffect, useState } from 'react';

import { IContentPage, IDBContentPage } from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { CardList, Button, CoverLink, Input, IImage } from '@netfront/ui-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { Page } from '../Page';

import { partnerHubPageConstants } from './PartnerHubPage.constants';
import { PartnerHubPageProps } from './PartnerHubPage.interfaces';
import styles from './PartnerHubPage.module.css';

import { useToast } from '../../../hooks';
import { PartnerHubCard } from '../../PartnerHubCard';

export const PartnerHubPage = ({ contentPages }: PartnerHubPageProps) => {
  const { PARTNER_HUB_PAGE_TITLE, PARTNER_HUB_SEO_DESCRIPTION, PARTNER_HUB_SEO_TITLE } = partnerHubPageConstants;

  const { isAuthenticated } = useAuthentication();
  const { push } = useRouter();

  const { handleToastError } = useToast();

  const [allBlogPages, setAllBlogPages] = useState<IDBContentPage[]>([]);
  const [allTags, setAllTags] = useState<string[]>();
  const [filteredBlogPages, setFilteredBlogPages] = useState<IDBContentPage[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filterBySearchText = (inputText?: string) => (blogPage: IDBContentPage) => {
    if (!inputText) {
      return true;
    }

    const { tags = [], title } = blogPage;

    if (!tags.length) {
      return false;
    }

    const [firstTag] = tags;

    const { name } = firstTag;

    return name.contains(inputText) || title.contains(inputText);
  };

  const filterByTag = (inputTags: string[]) => (blogPage: IContentPage) => {
    if (!inputTags.length) {
      return false;
    }

    const { tags = [] } = blogPage;

    if (!tags.length) {
      return false;
    }

    const [blogPageTag] = tags;

    return inputTags.includes(blogPageTag.name);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilteredBlogPages(allBlogPages.filter(filterBySearchText(value)).filter(filterByTag(selectedTags)));
    setSearchText(value);
  };
  const handleTagClick = (value: string) => {
    const updatedSelectedTags = selectedTags.includes(value)
      ? selectedTags.filter((selectedTag) => selectedTag !== value)
      : [...selectedTags, value];

    setFilteredBlogPages(allBlogPages.filter(filterByTag(updatedSelectedTags)).filter(filterBySearchText(searchText)));
    setSelectedTags(updatedSelectedTags);
  };

  useEffect(() => {
    const pageTags: string[] = [];
    contentPages.forEach((page) => page.tags?.forEach((tag) => pageTags.push(tag.name)));

    const uniqueSortedFlattenedTags = [...new Set(pageTags)];

    setAllBlogPages(contentPages);
    setAllTags(uniqueSortedFlattenedTags);
    setFilteredBlogPages(contentPages);
    setSelectedTags(uniqueSortedFlattenedTags);
  }, [contentPages]);

  const blogItems = filteredBlogPages.map(({ asset, tags = [], title, url }) => {
    if (!asset) {
      return [];
    }

    const { presignedUrl: src } = asset;

    if (!src) {
      return [];
    }

    const blogImage: IImage = {
      altText: title,
      src,
    };

    return (
      <PartnerHubCard
        key={title}
        coverLink={<CoverLink href={`/public/${url}`} supportiveText={`Go to article about ${title}`} />}
        image={blogImage}
        subTitle={tags[0]?.name}
        title={title}
      />
    );
  });

  useEffect(() => {
    if (!isAuthenticated) {
      push('/login').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Page meta={{ seoDescription: PARTNER_HUB_SEO_DESCRIPTION, seoTitle: PARTNER_HUB_SEO_TITLE }} title={PARTNER_HUB_PAGE_TITLE}>
      <div className="container">
        <h1 className="h1 color-red-700 mt-16">Partner hub</h1>

        <Input
          additionalClassNames={styles['c-partner-hub-search-input']}
          id="search-partners"
          labelText="Search partners"
          name="search partners"
          placeholder="Search"
          type="text"
          isLabelHidden
          onChange={handleSearchTextChange}
        />

        <div className="flex flex-wrap mb-5">
          {allTags &&
            allTags.map((tag) => {
              const isTagSelected = selectedTags.includes(tag);

              return (
                <Button
                  key={tag}
                  additionalClassNames={cx(styles['c-submit-button'], {
                    'c-submit-button--isSelected': isTagSelected,
                  })}
                  text={tag}
                  onClick={() => handleTagClick(tag)}
                />
              );
            })}
        </div>
        {Boolean(blogItems.length) && <CardList additionalClassNames="font-header" items={blogItems} />}
      </div>
    </Page>
  );
};
