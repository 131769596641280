import { NavigationButtonProps } from './NavigationButton.interfaces';

import { Button, CaretLeftIcon, CaretRightIcon } from '../../components';

const NavigationButton = ({ direction, moduleTitle, pageTitle, ...rest }: NavigationButtonProps) => {
  return pageTitle ? (
    <Button
      className={`bg-white color-white border border-accent-400 flex justify-center items-center p-4 mr-2 pointer rounded transition-200 hover:shadow-xl ${
        direction === 'NEXT' ? 'text-right ml-auto' : 'text-left'
      }`}
      {...rest}
    >
      {direction === 'PREVIOUS' && (
        <div className="color-primary mr-4">
          <CaretLeftIcon />
        </div>
      )}

      <div>
        <span className="color-primary weight-700 block mb-0">{moduleTitle}</span>
        <p className="weight-700 mb-0">{pageTitle}</p>
      </div>

      {direction === 'NEXT' && (
        <div className="color-primary ml-4">
          <CaretRightIcon />
        </div>
      )}
    </Button>
  ) : null;
};

export { NavigationButton };
