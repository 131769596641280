import { useEffect } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { ContentPageProps } from './ContentPage.interfaces';
import styles from './ContentPage.module.css';

import { Page } from '../../../components';

const ContentPage = ({ contentPage }: ContentPageProps) => {
  const { dispatch } = useContentPageContext();

  const { seoDescription, seoTitle, title } = contentPage;

  const {
    query: { pageUrl },
  } = useRouter();

  useEffect(() => {
    if (!pageUrl) {
      return;
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl]);

  return (
    <Page meta={{ seoDescription, seoTitle }} title={title}>
      <section className={`${styles['c-content-page']} bg-blue-100 bg-logo py-12 md:pb-32 md:pt-20`}>
        <div className="container container-md">
          <h1 className="ek-section h2 ">{title}</h1>
          <CMSContentPage />
        </div>
      </section>
    </Page>
  );
};

export { ContentPage };
