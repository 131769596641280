import React from 'react';

import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadcrumbItemLink } from './BreadcrumbItemLink';

const BreadcrumbItemDirectoryPageLink = () => {
  return <BreadcrumbItemLink content={<BreadcrumbItem text="Directory" />} href="/directory" />;
};

export { BreadcrumbItemDirectoryPageLink };
