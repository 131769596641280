import React from 'react';

import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadcrumbItemLink } from './BreadcrumbItemLink';

const BreadcrumbItemToolboxMediaPageLink = () => {
  return <BreadcrumbItemLink content={<BreadcrumbItem text="Media" />} href="/toolbox/media" />;
};

export { BreadcrumbItemToolboxMediaPageLink };
