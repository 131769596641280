import { ApolloError } from '@apollo/client';
import { ContactForm, useSendContactForm } from '@netfront/gelada-identity-library';

import { contactPageConstants } from './ContactPage.constants';

import { BUTTON_CLASSES, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const ContactPage = () => {
  const { CONTACT_ADDRESS_ICON, CONTACT_EMAIL_ICON, CONTACT_ICON_STYLES, CONTACT_PHONE_ICON, CONTACT_SECTION_STYLES, CONTACT_PAGE_TITLE } =
    contactPageConstants;

  const { handleToastError, handleToastSuccess } = useToast();

  const handleSendContactFormCompleted = () => {
    handleToastSuccess({
      message: 'Thank you for your message! We will be in touch shortly.',
    });
  };

  const handleSendContactFormError = (error: ApolloError) => {
    handleToastError({
      shouldUseFriendlyErrorMessage: true,
      error,
    });
  };

  const { handleSendContactForm } = useSendContactForm({
    onCompleted: handleSendContactFormCompleted,
    onError: handleSendContactFormError,
  });

  return (
    <SingleFormPage title={CONTACT_PAGE_TITLE} shouldOverrideContainer>
      <div className="lg:flex bg-white-polar">
        <section className="w-full lg:px-10 xl:px-20 lg:py-40">
          <div className="bg-white py-8 px-12">
            <h1 className="h1 mb-4 color-red-700">Get in touch</h1>
            <div className="w-full md:w-4/5 xl:w-1/2">
              <span className="font-size-h1-small line-height-1">Have any questions? Fill out the form below or give us a call.</span>
            </div>
            <ContactForm
              buttonClassName={`${BUTTON_CLASSES.default} mt-2`}
              isSubmitting={false}
              onSubmit={(email, message, firstName, lastName) => {
                void handleSendContactForm({
                  firstName: String(firstName),
                  lastName: String(lastName),
                  message,
                  sender: email,
                  placeholders: [
                    { key: '[Firstname]', value: String(firstName) },
                    { key: '[Lastname]', value: String(lastName) },
                    { key: '[Email]', value: email },
                    { key: '[Message]', value: message },
                  ]
                });
              }}
            />
          </div>
        </section>
        <section className="w-full px-20 py-12 xl:pl-50 lg:py-50 lg:pl-32 bg-red-700 color-white">
          <h2 className="mb-6 weight-700">Contact details</h2>

          <section className={CONTACT_SECTION_STYLES}>
            <img alt="email icon" className={CONTACT_ICON_STYLES} src={CONTACT_EMAIL_ICON} />
            <div>
              <div className="weight-600 mb-0">Email</div>
              <a className="color-white weight-300 font-size-h1-small" href="mailto:sales@ctls.com.au">
                sales@ctls.com.au
              </a>
            </div>
          </section>

          <section className={CONTACT_SECTION_STYLES}>
            <img alt="phone icon" className={CONTACT_ICON_STYLES} src={CONTACT_PHONE_ICON} />
            <div>
              <div className="weight-600 mb-0">Phone</div>
              <div className="color-white weight-300 font-size-h1-small">1300 882 664</div>
            </div>
          </section>

          <section className={CONTACT_SECTION_STYLES}>
            <img alt="address icon" className={CONTACT_ICON_STYLES} src={CONTACT_ADDRESS_ICON} />
            <div>
              <div className="weight-600 mb-0">Address</div>
              <div className="color-white weight-300 w-3/4 font-size-h1-small">14/ 156 -158 Parramatta Road Camperdown, NSW, 2050</div>
            </div>
          </section>
        </section>
      </div>
    </SingleFormPage>
  );
};

export { ContactPage };
