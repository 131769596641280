import { useState } from 'react';

import { SliderDirectionType } from '@netfront/ekardo-content-library';

import { BackgroundImageType } from './LandingPage.types';

import { Page, Slider } from '../../../components';
import { pageConstants } from '../../../constants';

const LandingPage = () => {
  const { LANDING_PAGE_IMAGE_TYPES, NEXT, LANDING_PAGE_SLIDE_DATA } = pageConstants;

  const [backgroundImageType, setBackgroundImageType] = useState<BackgroundImageType>('home');

  // TODO: Figure out why this will not accumulate in state
  let slideNumber = 0;
  const handleOnMoveStart = (direction?: SliderDirectionType) => {
    const isNext = direction === NEXT;

    if (isNext) {
      const isLastSlide = slideNumber === 3;

      slideNumber = isLastSlide ? 0 : slideNumber + 1;

      setBackgroundImageType(LANDING_PAGE_IMAGE_TYPES[slideNumber] as BackgroundImageType);
    } else {
      const isFirstSlide = slideNumber === 0;

      slideNumber = isFirstSlide ? 3 : slideNumber - 1;

      setBackgroundImageType(LANDING_PAGE_IMAGE_TYPES[slideNumber] as BackgroundImageType);
    }
  };

  return (
    <Page
      meta={{
        seoDescription: 'CTLS light fittings designed and manufactured to be renewed, reused, and recycled',
        seoTitle: 'CTLS - Commercial & Technical Lighting Solutions',
      }}
      title="CTLS light fittings designed and manufactured to be renewed, reused, and recycled"
    >
      <section className={`relative bg-logo bg-logo--large bg-logo--is-${backgroundImageType}`}>
        <div className="lg:container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <div className="w-11/12 mx-auto py-40 lg:py-48 xl:py-64">
                <Slider sliderData={LANDING_PAGE_SLIDE_DATA} onMoveStart={handleOnMoveStart} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export { LandingPage };
